// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// require("@rails/ujs").start()
// require("turbolinks").start()
// require("@rails/activestorage").start()
// require("channels")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// require("trix")
// require("@rails/actiontext")

import "controllers"

const OTP = require('@multiotp/genotp')

window.otp = {}
otp.get = (secret) => {
  let options = {
    algorithm: 'sha1', //sha1 (sha256|sha512 will be implemented later)
    digits:    6,      // 6|8   (number of digits)
    period:    30,     // 30|60 (for TOTP only, in seconds)
    secret:    secret,
    seedtype:  'base32',  // hex|base32|bin (secret seed format)
    type:      'totp', // totp|hotp|motp (otp type)
    values:    1,      // number of values to return
  }
  return new OTP(options).generate()
}
