import CheckboxSelectAll from "stimulus-checkbox-select-all"

export default class extends CheckboxSelectAll {
  send_sms(event) {
    event.preventDefault();

    let data = new FormData()
    this.checked.forEach((checkbox) => data.append("ids[]", checkbox.value))

    Rails.ajax({
      url: 'leads/bulk',
      type: 'POST',
      data: data
    })
  }
}
